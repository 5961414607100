




















































































import { mapGetters, mapActions, mapState } from 'vuex'
import moment from 'moment-timezone'
import { Parser } from 'json2csv'
import { INoteMenuData } from '@/store/modules/notes/types'

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

export default {
  name: 'NotesMenu',
  data: () : INoteMenuData => ({
    newNoteText: '',
    noteFormActive: false,
    loading: false,
    exportLoading: false
  }),
  computed: {
    ...mapState('users', ['status', 'user', 'client']),
    ...mapState('sites', ['activeSite', 'activeSiteId']),
    ...mapState('notes', ['notes']),
    ...mapGetters('sites', ['activeSite']),
    timezone() {
      return this.activeSite && this.activeSite.timezone
    }
  },
  async mounted() : Promise<void> {
    await this.fetchNotes(this.activeSiteId)
  },
  methods: {
    ...mapActions('notes', {
      createNote: 'CREATE_NOTE',
      fetchNotes: 'FETCH_NOTES'
    }),
    //called when submit button is clicked
    async addNote() : Promise<void> {
      //construct note object
      const note = {
        body: this.newNoteText,
        name: this?.user?.name,
        createdAt: new Date().toISOString(),
        siteId: this.activeSiteId.toString()
      }
      this.loading = true
      await this.createNote(note)
      this.noteFormActive = false
      this.newNoteText = ''
      this.loading = false
    },
    openDialog(name : string) {
      return this.$bus.$emit('openDialog', name)
    },
    stopPropagation(event) : void {
      event.stopPropagation()
    },
    getNoteDate(dateString : string) : string {
      if (!this.timezone) { return "" }

      return moment.utc(dateString)
          .tz(this.timezone)
          .format('YYYY-MM-DD')
    },
    getNoteTime(dateString : string) : string {
      if (!this.timezone) { return "" }

      return moment.utc(dateString)
          .tz(this.timezone)
          .format('HH:mm:ss z')
    },
    exportNotes() : void {
      if (!this?.notes?.length) return
      this.exportLoading = true
      const exportFileName = `${
        this?.activeSite?.name
      }-${new Date().toISOString()}-notes.csv`
      const parser = new Parser({ fields: ['name', 'created_at', 'body'] })
      const cleanedNotes = this.notes.map(i => ({
        ...i,
        body: i.body.trim()
      }))
      const csv = parser.parse(cleanedNotes)
      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      //IE11 & Edge
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csvData, exportFileName)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(csvData)
        link.setAttribute('download', exportFileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      this.exportLoading = false
    },
    toggleNoteForm() : void {
      this.newNoteText = ''
      this.noteFormActive = !this.noteFormActive
    }
  },
  watch: {
    activeSiteId(e) : void {
      this.fetchNotes(e)
    }
  }
}
